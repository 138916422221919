import React from 'react';
import Layout from '../components/layout/Layout';

export default () => (
  <Layout className="top-0">
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Page not found
          </h1>
        </div>
      </div>
    </section>
  </Layout>
);
